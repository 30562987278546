/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ---------------------------------------------------------------------------------------------
    Date         Author   Comment
    ---------------------------------------------------------------------------------------------
    2024-06-10	  Khyati   This is private routes, which are not accessible if user not logged in
*/

import React from "react";
import { Navigate } from "react-router-dom";

import Layout from "../components/commonComponents/Layout";

const PrivateRoute = () => {
  // storing authentication token
  const auth_token = localStorage.getItem("access_token");

  return auth_token ? <Layout /> : <Navigate to={"/login"} />;
};

export default PrivateRoute;
