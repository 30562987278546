/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ----------------------------------------------------------
    Date         Author   Comment
    ----------------------------------------------------------
    2024-09-24	  Khyati   This is download video reducer file
*/

import {
  VIDEO_DOWNLOAD_START,
  VIDEO_DOWNLOAD_SUCCESS,
  VIDEO_DOWNLOAD_FAILURE,
  VIDEO_DOWNLOAD_RESET,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const VideoDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_DOWNLOAD_START:
      return { ...state, loading: true, error: null };
    case VIDEO_DOWNLOAD_SUCCESS:
      return { ...state, data: "success", loading: false };
    case VIDEO_DOWNLOAD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case VIDEO_DOWNLOAD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default VideoDownloadReducer;
