/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ----------------------------------------------------
    Date         Author   Comment
    ----------------------------------------------------
    2023-12-11	  Khyati   This is add user reducer file
*/

import {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  RESET_USER,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const AddUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ADD_USER_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ADD_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default AddUserReducer;
