/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------------------
    2023-11-28	  Khyati   This is store where APIs data will be stored
*/

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "../reducers/index";

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
