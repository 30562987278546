/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    --------------------------------------------------
    Date         Author   Comment
    --------------------------------------------------
    2023-12-11	  Khyati   This is login reducers file
*/

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
} from "../../actions/types";

const initialState = {
  user: null,
  credentials: {},
  loading: false,
  error: null,
  message: "",
  token: "",
  status: 0,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload?.result,
        message: action.payload.message,
        token: action.payload.result?.access_token,
        status: action.payload.status_code,
        loading: false,
      };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case LOGOUT_REQUEST:
      return {
        ...initialState,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

export default authReducer;
