/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------------------
    2023-12-01	  Khyati   This component returns header of the project
*/

import React, { useState, useRef, useEffect } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import logoutAction from "../../redux/actions/auth/LogoutAction";

import { userMenuOptions } from "../../utils";

import LoginLogo from "../assets/images/LoginLogo.jpg";

const Header = ({ openMenu, setOpenMenu }) => {
  // store logged in user name
  const [userMenu, setUserMenu] = useState(false);
  // store icons menu
  const [iconMenu, setIconMenu] = useState(false);

  // use for user info
  const userMenuRef = useRef(null);
  // use for API call
  const dispatch = useDispatch();
  // use for navigation
  const navigate = useNavigate();

  // handle outside click function
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // handle menu open and close function
  const handleToggle = () => {
    setOpenMenu(!openMenu);
  };

  // function is for handle user menu visible and hide
  const handleUserMenu = () => {
    setUserMenu(!userMenu);

    if (iconMenu) {
      setIconMenu(false);
    }
  };

  // handle logout
  const Logout = (e) => {
    e.preventDefault();

    dispatch(logoutAction());
    navigate("/login");
  };

  return (
    <div
      className={
        "bg-white px-3 py-1 flex items-center justify-between sticky top-0 w-full shadow-md z-10"
      }
    >
      {/* header leftside */}
      <div
        className={
          "flex justify-between items-center md:w-full md:max-w-[255px]"
        }
      >
        {/* logo of the project company */}
        <Link to={"/"}>
          <img src={LoginLogo} alt={"logo"} className={"py-1 h-20"} />
        </Link>

        {/* icon for menu expand and collapse */}
        {openMenu ? (
          <XMarkIcon
            className={"mx-2 h-5 w-5 cursor-pointer"}
            onClick={handleToggle}
          />
        ) : (
          <Bars3Icon
            className={"mx-2 h-5 w-5 cursor-pointer"}
            onClick={handleToggle}
          />
        )}
      </div>

      {/* header right side */}
      <div className={"flex items-center justify-end text-right"}>
        {/* user name and it's menu */}
        <div
          ref={userMenuRef}
          className={"relative flex items-center ml-2 cursor-pointer"}
          onClick={handleUserMenu}
        >
          <p className={"text-sm text-Dark"}>
            Welcome, {localStorage.getItem("first_name")}
          </p>

          {/* user settings menu */}
          {userMenu && (
            <div
              className={
                "absolute right-0 top-[30px] bg-maritimeBlue shadow-lg text-white w-64 p-2 rounded-md hover:bg-volcanicSand"
              }
            >
              {userMenuOptions.map((item) => (
                <div
                  key={item.id}
                  onClick={Logout}
                  className={"w-full py-1 px-3"}
                >
                  {item.name === "Logout" ? (
                    <div className={"flex items-center"}>
                      <i className={"pi pi-power-off text-[1.1rem]"} />
                      <p className={"cursor-pointer w-fit mx-3"}>{item.name}</p>
                    </div>
                  ) : (
                    <Link to={item.slug} key={item.id}>
                      {item.name}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
