/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ---------------------------------------------------------------------------
    Date         Author   Comment
    ---------------------------------------------------------------------------
    2023-12-11	  Khyati   This is types file for all APIs actions and reducers
*/

// authentication
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const RECREATE_ACCESS_TOKEN_REQUEST = "RECREATE_ACCESS_TOKEN_REQUEST";
export const RECREATE_ACCESS_TOKEN_SUCCESS = "RECREATE_ACCESS_TOKEN_SUCCESS";
export const RECREATE_ACCESS_TOKEN_FAILURE = "RECREATE_ACCESS_TOKEN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const SEND_OTP_REQURST = "SEND_OTP_REQURST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";
export const SEND_OTP_RESET = "SEND_OTP_RESET";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const VERIFY_FORGOTPASSWORD_OTP_REQUEST =
  "VERIFY_FORGOTPASSWORD_OTP_REQUEST";
export const VERIFY_FORGOTPASSWORD_OTP_SUCCESS =
  "VERIFY_FORGOTPASSWORD_OTP_SUCCESS";
export const VERIFY_FORGOTPASSWORD_OTP_FAILURE =
  "VERIFY_FORGOTPASSWORD_OTP_FAILURE";
export const VERIFY_FORGOTPASSWORD_OTP_RESET =
  "VERIFY_FORGOTPASSWORD_OTP_RESET";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
// authentication

// branch list
export const BRANCH_LIST_REQUEST = "BRANCH_LIST_REQUEST";
export const BRANCH_LIST_SUCCESS = "BRANCH_LIST_SUCCESS";
export const BRANCH_LIST_FAILURE = "BRANCH_LIST_FAILURE";

export const GET_USER_BRANCH_REQUEST = "GET_USER_BRANCH_REQUEST";
export const GET_USER_BRANCH_SUCCESS = "GET_USER_BRANCH_SUCCESS";
export const GET_USER_BRANCH_FAILURE = "GET_USER_BRANCH_FAILURE";
export const GET_USER_BRANCH_RESET = "GET_USER_BRANCH_RESET";
// branch list

// dashboard
export const LOAN_COUNT_LIST_REQUEST = "LOAN_COUNT_LIST_REQUEST";
export const LOAN_COUNT_LIST_SUCCESS = "LOAN_COUNT_LIST_SUCCESS";
export const LOAN_COUNT_LIST_FAILURE = "LOAN_COUNT_LIST_FAILURE";

export const VIDEO_PD_LIST_REQUEST = "VIDEO_PD_LIST_REQUEST";
export const VIDEO_PD_LIST_SUCCESS = "VIDEO_PD_LIST_SUCCESS";
export const VIDEO_PD_LIST_FAILURE = "VIDEO_PD_LIST_FAILURE";

export const VIDEO_PD_USER_WISE_LIST_REQUEST =
  "VIDEO_PD_USER_WISE_LIST_REQUEST";
export const VIDEO_PD_USER_WISE_LIST_SUCCESS =
  "VIDEO_PD_USER_WISE_LIST_SUCCESS";
export const VIDEO_PD_USER_WISE_LIST_FAILURE =
  "VIDEO_PD_USER_WISE_LIST_FAILURE";
// dashboard

// role
export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAILURE = "ROLE_LIST_FAILURE";
// role

// user data
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_USER_TYPE_REQUEST = "GET_USER_TYPE_REQUEST";
export const GET_USER_TYPE_SUCCESS = "GET_USER_TYPE_SUCCESS";
export const GET_USER_TYPE_FAILURE = "GET_USER_TYPE_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const RESET_USER = "RESET_USER";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";
export const RESET_EDIT_USER = "RESET_EDIT_USER";

export const GET_REPORTING_USER_REQUEST = "GET_REPORTING_USER_REQUEST";
export const GET_REPORTING_USER_SUCCESS = "GET_REPORTING_USER_SUCCESS";
export const GET_REPORTING_USER_FAILURE = "GET_REPORTING_USER_FAILURE";

export const ACTIVE_INACTIVE_USER_REQUEST = "ACTIVE_INACTIVE_USER_REQUEST";
export const ACTIVE_INACTIVE_USER_SUCCESS = "ACTIVE_INACTIVE_USER_SUCCESS";
export const ACTIVE_INACTIVE_USER_FAILURE = "ACTIVE_INACTIVE_USER_FAILURE";
export const RESET_ACTIVE_INACTIVE_USER = "RESET_ACTIVE_INACTIVE_USER";
// user list

// video PD
export const USER_TYPE_AGAINST_LOAN_REQUEST = "USER_TYPE_AGAINST_LOAN_REQUEST";
export const USER_TYPE_AGAINST_LOAN_SUCCESS = "USER_TYPE_AGAINST_LOAN_SUCCESS";
export const USER_TYPE_AGAINST_LOAN_FAILURE = "USER_TYPE_AGAINST_LOAN_FAILURE";
export const RESET_USER_TYPE_AGAINST_LOAN = "RESET_USER_TYPE_AGAINST_LOAN";

export const GENERATE_VIDEO_PD_REQUEST = "GENERATE_VIDEO_PD_REQUEST";
export const GENERATE_VIDEO_PD_SUCCESS = "GENERATE_VIDEO_PD_SUCCESS";
export const GENERATE_VIDEO_PD_FAILURE = "GENERATE_VIDEO_PD_FAILURE";
export const RESET_VIDEO_PD = "RESET_VIDEO_PD";

export const GET_INDIVIDUAL_VIDEO_PD_REQUEST =
  "GET_INDIVIDUAL_VIDEO_PD_REQUEST";
export const GET_INDIVIDUAL_VIDEO_PD_SUCCESS =
  "GET_INDIVIDUAL_VIDEO_PD_SUCCESS";
export const GET_INDIVIDUAL_VIDEO_PD_FAILURE =
  "GET_INDIVIDUAL_VIDEO_PD_FAILURE";
export const RESET_INDIVIDUAL_VIDEO_PD = "RESET_INDIVIDUAL_VIDEO_PD";

export const VIDEO_UPLOAD_REQUEST = "VIDEO_UPLOAD_REQUEST";
export const VIDEO_UPLOAD_SUCCESS = "VIDEO_UPLOAD_SUCCESS";
export const VIDEO_UPLOAD_FAILURE = "VIDEO_UPLOAD_FAILURE";
export const VIDEO_UPLOAD_RESET = "VIDEO_UPLOAD_RESET";

export const VIDEO_UPLOAD_START_ONLINE_REQUEST =
  "VIDEO_UPLOAD_START_ONLINE_REQUEST";
export const VIDEO_UPLOAD_START_ONLINE_SUCCESS =
  "VIDEO_UPLOAD_START_ONLINE_SUCCESS";
export const VIDEO_UPLOAD_START_ONLINE_FAILURE =
  "VIDEO_UPLOAD_START_ONLINE_FAILURE";
export const VIDEO_UPLOAD_START_ONLINE_RESET =
  "VIDEO_UPLOAD_START_ONLINE_RESET";
// video PD

// history
export const EXPORT_HISTORY_REQUEST = "EXPORT_HISTORY_REQUEST";
export const EXPORT_HISTORY_SUCCESS = "EXPORT_HISTORY_SUCCESS";
export const EXPORT_HISTORY_FAILURE = "EXPORT_HISTORY_FAILURE";
export const EXPORT_HISTORY_RESET = "EXPORT_HISTORY_RESET";

export const DOWNLOAD_VIDEO_REQUEST = "DOWNLOAD_VIDEO_REQUEST";
export const DOWNLOAD_VIDEO_SUCCESS = "DOWNLOAD_VIDEO_SUCCESS";
export const DOWNLOAD_VIDEO_FAILURE = "DOWNLOAD_VIDEO_FAILURE";
export const DOWNLOAD_VIDEO_RESET = "DOWNLOAD_VIDEO_RESET";

export const VIDEO_DOWNLOAD_START = "VIDEO_DOWNLOAD_START";
export const VIDEO_DOWNLOAD_SUCCESS = "VIDEO_DOWNLOAD_SUCCESS";
export const VIDEO_DOWNLOAD_FAILURE = "VIDEO_DOWNLOAD_FAILURE";
export const VIDEO_DOWNLOAD_RESET = "VIDEO_DOWNLOAD_RESET";
// history

// score card
export const EXPORT_SCORE_CARD_REQUEST = "EXPORT_SCORE_CARD_REQUEST";
export const EXPORT_SCORE_CARD_SUCCESS = "EXPORT_SCORE_CARD_SUCCESS";
export const EXPORT_SCORE_CARD_FAILURE = "EXPORT_SCORE_CARD_FAILURE";
export const EXPORT_SCORE_CARD_RESET = "EXPORT_SCORE_CARD_RESET";
// score card
