/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------------------------------------
    2024-03-01	  Khyati   This is public routes which are not accessible after logged in
*/

import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import Loader from "../components/commonComponents/Loader";

const PublicRoutes = () => {
  // storing authentication token
  const auth_token = localStorage.getItem("access_token");

  return auth_token ? (
    <Navigate to={"/"} />
  ) : (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};

export default PublicRoutes;
