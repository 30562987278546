/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------------------------
    2023-12-11	  Khyati   This is routing file, where all routes are defined
*/

import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";

// login component
const Login = lazy(() => import("../components/auth/login"));
// reset password component
const ResetPassword = lazy(() => import("../components/auth/resetPassword"));
// dashboard component
const Dashboard = lazy(() => import("../components/dashboard"));
// user management component
const UserManagement = lazy(() => import("../components/userManagement"));
// add user component
const AddUser = lazy(() => import("../components/userManagement/AddUser"));
// branch component
const Branch = lazy(() => import("../components/branch"));
// score card component
const ScoreCard = lazy(() => import("../components/report/ScoreCard"));
// videoPD form component
const VideoPDForm = lazy(() => import("../components/videoPD/VideoPDForm"));
// individual report component
const IndividualReport = lazy(() =>
  import("../components/report/IndividuleReport")
);
// history component
const History = lazy(() => import("../components/report/History"));
// videoPD form edit component
const VideoPDFormEdit = lazy(() =>
  import("../components/videoPD/VideoPDFormEdit")
);
// not found component
const NotFound = lazy(() => import("../components/commonComponents/404"));

const RoutesList = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {/* Dashboard/home page */}
        <Route path={"/"} element={<Dashboard />} />
        {/* setting menu of sidebar */}
        <Route exact path={"/manage-user"} element={<UserManagement />} />
        <Route exact path={"/manage-user/add-user"} element={<AddUser />} />
        <Route exact path={"/branches"} element={<Branch />} />
        {/* report menu of side bar */}
        <Route exact path={"/report/score-card"} element={<ScoreCard />} />
        <Route exact path={"/report"} element={<IndividualReport />} />
        <Route exact path={"/report/history"} element={<History />} />
        <Route exact path={"/analysis/video-pd"} element={<VideoPDForm />} />
        <Route
          exact
          path={"/analysis/video-pd-edit"}
          element={<VideoPDFormEdit />}
        />
        {/* not found page */}
        <Route exact path={"*"} element={<NotFound />} />
      </Route>

      {/* login and reset password page */}
      <Route element={<PublicRoutes />}>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/reset-password"} element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default RoutesList;
