/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------------------------------------
    2023-12-01	  Khyati   This component is retuns the common layout for all the pages
*/

import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Loader from "./Loader";

import { classNames } from "../../utils";

const Layout = () => {
  // use for sidebar open/close
  const [openMenu, setOpenMenu] = useState(true);

  return (
    <>
      {/* header */}
      <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <div className={"flex bg-aliceBlue"}>
        {/* sidebar */}
        <Sidebar openMenu={openMenu} setOpenMenu={setOpenMenu} />

        <div
          className={classNames(
            openMenu
              ? "sm:w-openMenuWidth w-full"
              : "sm:w-closeMenuWidth w-full",
            "p-3 bg-aliceBlue min-h-calcHeight"
          )}
        >
          {/* main body */}
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Layout;
