/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------
    2023-12-11	  Khyati   This is role list reducer file
*/

import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAILURE,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GetRoleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case ROLE_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ROLE_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default GetRoleListReducer;
