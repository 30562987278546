/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------
    2023-12-11	  Khyati   This is edit user reducer file
*/

import {
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  RESET_EDIT_USER,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const EditUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_USER_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case EDIT_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_EDIT_USER:
      return initialState;
    default:
      return state;
  }
};

export default EditUserReducer;
