/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------------------------------
    2024-02-20	  Khyati   This is reducer file for checking loan against user type
*/

import {
  USER_TYPE_AGAINST_LOAN_REQUEST,
  USER_TYPE_AGAINST_LOAN_SUCCESS,
  USER_TYPE_AGAINST_LOAN_FAILURE,
  RESET_USER_TYPE_AGAINST_LOAN,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const UserTypeAgainstLoanList = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPE_AGAINST_LOAN_REQUEST:
      return { ...state, loading: true, error: null };
    case USER_TYPE_AGAINST_LOAN_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case USER_TYPE_AGAINST_LOAN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER_TYPE_AGAINST_LOAN:
      return initialState;
    default:
      return state;
  }
};

export default UserTypeAgainstLoanList;
